import React, { useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import CustomBreadcrumb from '../../../components/CustomBreadcrumb';
import VideoCategoryNav from '../../../components/VideoCategoryNav';
import { useLocation } from '@reach/router';

const SonyCreCTen = ({ pageContext }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	const [videoCount, setVideoCount] = useState(6);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const location = useLocation();

	const videos = [
		{
			id: 'mGah42Robj4',
			url: 'https://www.youtube.com/embed/mGah42Robj4?si=aF-OqiY6kpfRa9N5',
			title: 'How to insert batteries on Sony CRE-C10 OTC Hearing Aids | hear.com',
			subtitle: 'Learn how to insert batteries on Sony CRE-C10 Over-the-counter hearing aids.'
		},
		{
			id: 'wSremDbrvLI',
			url: 'https://www.youtube.com/embed/wSremDbrvLI?si=KPgC49Npkg7XiFj1',
			title: 'How to change the click sleeves and clean the Sony CRE-C10 OTC Hearing Aids | hear.com',
			subtitle: 'Learn to change click sleeves and clean your CRE-C10 Self-fitting OTC Hearing Aids with ease.'
		},
		{
			id: 'S2O5sXrT2ro',
			url: 'https://www.youtube.com/embed/S2O5sXrT2ro?si=NK4L5QomxOU2lFCO',
			title: 'How to pair Sony CRE-C10 OTC Hearing Aids with Sony Hearing Control App | hear.com',
			subtitle:
				'Learn how to pair the Sony CRE-C10 OTC Hearing Aids to the Sony Hearing Control App for iOS™ and Android™'
		},
		{
			id: 'koNlHJjbtZY',
			url: 'https://www.youtube.com/embed/koNlHJjbtZY?si=_0xwQcuVGQr4RH2F',
			title: 'How to Insert , fit , and remove Sony CRE-C10 OTC Hearing Aids | hear.com',
			subtitle: 'Follow these easy steps to learn how to insert, fit and remove the Sony CRE-C10 hearing aids.'
		}
	];

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const videoId = params.get('videoID');
		if (videoId) {
			const video = videos.find((v) => v.id === videoId);
			setSelectedVideo(video);
		}
	}, [location]);

	const handleShowMore = () => {
		setVideoCount((prevCount) => prevCount + 6);
	};

	const visibleVideos = videos.slice(0, videoCount);

	return (
		<Layout>
			<Seo
				title="hear.com hearing aids instructional videos | hear.com"
				description="Watch videos on care, maintenance, cleaning, how-to guides, and testimonials for in-the-ear & behind-the-ear hearing aids by hear.com"
			/>
			<div className="o-wrapper">
				<div className="o-row-3p5"></div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<VideoCategoryNav />
				<div className="c-video-cards-wrapper o-row-3p5">
					{selectedVideo ? (
						<div className="c-video-container">
							<iframe
								className="c-video-card"
								title={selectedVideo.title}
								src={selectedVideo.url}
								allow="accelerometer; autoplay; picture-in-picture"
								allowFullScreen
							></iframe>
							<p className="c-video-title">{selectedVideo.title}</p>
							<p className="c-video-subtitle">{selectedVideo.subtitle}</p>
						</div>
					) : (
						visibleVideos.map((video, index) => (
							<div className="c-video-container" key={index}>
								<iframe
									className="c-video-card"
									title={video.title}
									src={video.url}
									loading={index < 6 ? 'eager' : 'lazy'}
									allow="accelerometer; autoplay; picture-in-picture"
									allowFullScreen
								></iframe>
								<p className="c-video-title">{video.title}</p>
								<p className="c-video-subtitle">{video.subtitle}</p>
							</div>
						))
					)}
				</div>
				{videos.length > videoCount && (
					<div className="c-show-more-wrapper">
						<button className="c-show-more-btn" onClick={handleShowMore}>
							Show More
						</button>
					</div>
				)}
			</div>
		</Layout>
	);
};

export default SonyCreCTen;
